@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,400;0,500;1,600;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@property --scrollable-percentage-left {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

@property --scrollable-percentage-right {
  syntax: '<percentage>';
  initial-value: 100%;
  inherits: true;
}

body {
  @apply font-sans;
}

@media (min-width: 900px) {
  body {
    @apply min-w-[1300px] overflow-x-scroll;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-time-picker__wrapper {
    @apply border-transparent
}

.react-time-picker__inputGroup {
    @apply pl-1.5
}

.react-time-picker__inputGroup__input {
    @apply appearance-none
}
@tailwind base;
@tailwind components;
@tailwind utilities;

#legalDocument figure.table table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

#legalDocument figure.table table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

#legalDocument figure.table table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

#legalDocument figure.table table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

#legalDocument figure.table table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

#legalDocument figure.table table tbody tr td hr {
  border-bottom: 1px solid black;
}

select {
  appearance: none;
  background-image: url('/src/assets/icons/chevron-down.svg');
  background-size: 0.75rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

.react-select-sort__control {
  @apply !border-med-gray-3 text-sm text-neutral-800 font-medium;
  min-width: 122px;
}

.react-select__control {
  @apply !border-med-gray-3 px-[7px] py-[8px] !rounded-[5px];
  min-width: 122px;
}

.react-select__control--is-focused {
  @apply !border-clc-blue border-2 shadow-none;
  min-width: 122px;
}


.react-select-with-icon__control,
.react-select-with-error__control {
  padding: 0.15rem 0.15rem 0.15rem 0.3rem;
  min-width: 122px;
  height: 46px;
}

.react-select-with-error__control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none !important;
  background-color: #fff4c6 !important;
}

.react-select-with-icon__control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #e5e7eb !important;
  box-shadow: none !important;
}

.react-select-with-icon__input-container {
  max-width: 28px;
  overflow: hidden;
}

.react-select-with-icon__indicator-separator, .react-select__indicator-separator {
  display: none !important;
}

.react-select-with-icon__indicator {
  color: #666 !important;
}

.react-select-with-icon__menu {
  margin-top: 0 !important;
}

.react-select__multi-value,
.react-select-with-icon__multi-value {
  border-radius: 0.4rem !important;
  background-color: #f0f2fd !important;
  border: 1px solid #00000026;
}

.react-select__multi-value__remove:hover {
  background-color: #f0f2fd !important;
  color: #000 !important;
}

div#interactive video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

#interactive.viewport canvas,
video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 100%;
  height: 100%;
}

.react-select__option.react-select__option {
  color: #333;
  font-weight: 600;
  white-space: normal;
}

#waiting-room-video-container > div:nth-child(2) {
  width: 100% !important;
  height: 460px !important;
  border-radius: 10px;
}

@media screen and (max-width: 900px) {
  #waiting-room-video-container > div:nth-child(2) {
    height: 100% !important;
  }
}

@media screen and (max-width: 900px) {
  #layoutContainer {
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
    width: 100% !important;
  }
}

/* Hiding mute mic returned by Vonage */
#MP_camera_publisher_default_controls > button:nth-child(1) {
  display: none;
}

/* Variables */
:root {
  --disabledColor: #ccc;
  --border-radius-base: 6px;
  --primary-color: #2b43da;
}

/* Styles */
.rc-slider {
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  border-radius: var(--border-radius-base);
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #00000026;
  border-radius: var(--border-radius-base);
}

.rc-slider-track,
.rc-slider-tracks {
  position: absolute;
  height: 5px;
  background-color: #2b43da;
  border-radius: var(--border-radius-base);
}

.rc-slider-track-draggable {
  z-index: 1;
  box-sizing: content-box;
  background-clip: content-box;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  transform: translateY(-5px);
}

.rc-slider-handle {
  position: absolute;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #2b43da;
  border: solid 2px #2b43da;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  touch-action: pan-x;
}

.rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}

.rc-slider-handle:focus-visible {
  border-color: #2b43da;
}

.rc-slider-handle-click-focused:focus {
  box-shadow: unset;
}

.rc-slider-handle:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #999;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-wrap: nowrap;
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .rc-slider-mark-text {
    font-size: 14px;
  }
}

.rc-slider-mark-text-active {
  color: #2b43da;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-dot {
  display: none;
  position: absolute;
  bottom: -2px;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  cursor: pointer;
}

.rc-slider-dot-active {
  border-color: #2b43da;
}

.rc-slider-dot-reverse {
  margin-right: -4px;
}

.rc-slider.disabled {
  background-color: #e9e9e9;
}

.rc-slider.disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider.disabled .rc-slider-handle,
.rc-slider.disabled .rc-slider-dot {
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}

.rc-slider.disabled .rc-slider-mark-text,
.rc-slider.disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-handle {
  position: absolute;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #2b43da;
  border: solid 2px #2b43da;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  touch-action: pan-x;
}

.rc-slider-handle::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 4px solid white;
}

.rc-slider-handle::after {
  content: '';
  position: absolute;
  top: 2px;
  right: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 4px solid white;
}

.persona-widget__iframe {
  width: 400px;
  height: 550px;
}

@media screen and (max-width: 768px) {
  .persona-widget__iframe {
    width: 100%;
    height: 600px;
  }
}

@media print {
  #root {
    overflow: hidden;
    max-height: 100vh;
  }
}

.navBarPrimaryNavigationElement:hover .navBarHoverDisplay {
  background-image: linear-gradient(
    180deg,
    rgba(43, 67, 218, 0) 0%,
    rgba(43, 67, 218, 0.25) 100%
  );
}

@media (min-width: 1139px) {
  .auth-login__width {
    width: 990px;
  }
}

@media (max-width: 1139px) {
  .auth-login__width {
    width: auto;
  }
}

.registerTestKitButton {
  transition: background-color .2s, color .2s;
}

.registerTestKitBanner:hover .registerTestKitButton {
  --tw-text-opacity: 1;
  color: rgb(43 67 218 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.input-border__gray {
  border-color: #666;
}

.react-time-picker__inputGroup__input:invalid {
  background: transparent;
}

.react-time-picker__inputGroup__input {
  color: #262626;
}

.react-time-picker__inputGroup__input::placeholder {
  color: #666666;
}

.react-time-picker__inputGroup {
  display: flex;
  /*justify-content: space-around;*/
  align-items: center;
  font-weight: 600;
}

